import React, {Component} from "react";
import {Col, Row, Form, Input, Button, Modal, FormGroup, ToggleButton, ButtonGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {ReactMultiEmail, isEmail} from "react-multi-email";
import 'react-multi-email/style.css';
import {Redirect} from "react-router-dom"
import {
    resetCreateProgressWall,
    updateDeliveryDate,
    updateDeliveryTime,
    updateIsPremium,
    updateRecipientList
} from "../actions/index";
import axios from 'axios';
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import "react-datetime/css/react-datetime.css";
import './DeliveryComponent.css'
import TimePicker from 'react-bootstrap-time-picker';
import ScaleLoader from "react-spinners/ScaleLoader";
import {extractConfettiColorsOnly} from "../utils/utils";

class DeliveryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: {},
            showErrorModal: false,
            redirectToPayment: false,
            redirectToSaveConfirmation: false,
            validated: false,
            submitPressed: "",
        }


        this.handleDeliveryTimeChange = this.handleDeliveryTimeChange.bind(this);
        this.handleDeliveryDateChange = this.handleDeliveryDateChange.bind(this);
        this.handleRecipientListChange = this.handleRecipientListChange.bind(this);
        this.setIsPremium = this.setIsPremium.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.submitBoard = this.submitBoard.bind(this);
    }


    handleDeliveryDateChange(event) {

        this.props.dispatch(updateDeliveryDate({
                deliveryDate: event.target.value
            }
        ))
    }

    handleDeliveryTimeChange(event) {

        this.props.dispatch(updateDeliveryTime({
                deliveryTime: event
            }
        ))
    }

    handleRecipientListChange(event) {
        //Restrict number of recipients to 10
        event = event.slice(0, 10)
        this.props.dispatch(updateRecipientList({
                recipientList: event
            }
        ))
    }

    additionalValidation() {
        let errors = {}

        let limit = new Date(new Date().getTime() + 1 * 60000);

        if (new Date(Date.parse(this.props.deliveryDate) + this.props.deliveryTime * 1000) < limit) {
            errors["Delivery Date"] = "Delivery date and time must be at least 1 minute in the future"
        }
        if (this.props.recipientList.length === 0) {
            errors["Recipient Email List"] = "No recipients specified"
        }
        return errors;

    }

    submitBoard(event) {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({
                validated: true
            })
            return
        }

        let errors = this.additionalValidation()
        if (Object.keys(errors).length === 0) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data"
                }
            }

            this.setState({"loading": true})
            let colors = extractConfettiColorsOnly(this.props.confettiColorsAndIds)

            let form = new FormData()

            let json = JSON.stringify(
                {
                    text: this.props.wallTitle,
                    centred: this.props.isCentred,
                    tile: this.props.s3Id,
                    s3Id: this.props.s3Id,
                    mainImageId: this.props.mainImageId,
                    mainImagePath: this.props.mainImagePath,
                    transparent: this.props.isTransparent,
                    font: this.props.font,
                    fontSize: this.props.fontSize,
                    fontColour: this.props.fontColour,
                    deliveryDate: this.props.deliveryDate,
                    deliveryTime: this.props.deliveryTime,
                    recipientList: this.props.recipientList,
                    active: false,
                    draft: true,
                    premium: this.props.isPremium,
                    sent: false,
                    paid: false,
                    sending: false,
                    confetti: this.props.isConfetti,
                    confettiColorsList: colors
                }
            )

            form.append("walldata", json)

            if (this.props.mainImageUploadedPicture != null) {
                form.append("file", this.props.mainImageUploadedPicture.image)
            } else {
                form.append("file", null)
            }

            axios.post(`${process.env.REACT_APP_SERVER_URL}createwallv2`,
                form,
                config).then(result => {

                let isPremium = this.props.isPremium
                if (this.state.submitPressed.toLowerCase() === "payment") {
                    this.setState({
                        isPremium: isPremium,
                        loading: false,
                        redirectToPayment: true,
                        redirectToSaveConfirmation: false,
                        uuid: result.data.uuid
                    })
                } else {
                    this.setState({
                        loading: false,
                        redirectToSaveConfirmation: true,
                        redirectToPayment: false,
                        uuid: result.data.uuid
                    })
                }

                this.props.dispatch(resetCreateProgressWall())

            }).catch(err => {

                let errorMessage = err.toString()

                if (errorMessage.startsWith("Error: Request failed with status code 412")){
                    errors = {
                        "Almost There!": "To create a wall, please verify your e-mail by clicking the verification link that was sent to your e-mail address."
                    }
                } else {
                    errors = {
                        "Server Error": err.toString(),
                    }
                }

                this.setState({errors: errors, loading: false})
                this.openModal();
            })

        } else {

            this.setState({errors: errors})
            this.openModal()
        }
    }

    setIsPremium(event){
        this.props.dispatch(updateIsPremium({
                isPremium: event.target.checked
            }
        ))

    }

    openModal() {
        this.setState({showErrorModal: true})
    }

    closeModal() {
        this.setState({showErrorModal: false})
    }

    render() {

        this.props.updateCurrentBreadcrumb("ScheduleDelivery")

        const {redirectToPayment, redirectToSaveConfirmation} = this.state

        if (redirectToPayment) {
            if (!this.state.isPremium){
                return (
                    <Redirect to={'/createwall/payment/' + this.state.uuid + '/freewall'}/>
                )
            } else {
                return (
                    <Redirect to={'/createwall/payment/' + this.state.uuid + '/paidwall'}/>
                )
            }
        }

        if (redirectToSaveConfirmation) {
            return (
                <Redirect to={'/createwall/confirmation/' + this.state.uuid + '/draft'}/>
            )
        }

        let errors = this.state.errors
        let errorsJsx = Object.keys(errors).map(function (key, index) {
            return (<div><h6>{key}</h6><p>{errors[key]}</p></div>)
        })

        return (
            <div>
                {this.state.loading && <div style = {{textAlign: "center", marginTop: "50px"}}>

                        <ScaleLoader style={{display: "inline-block"}} color={"#8639F1"} loading={this.state.loading} size={150}/>

                </div>}
                {!this.state.loading && <div>
                    <Modal show={this.state.showErrorModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            {errorsJsx}
                        </Modal.Body>
                    </Modal>
                    <br></br>
                    <Form noValidate validated={this.state.validated} onSubmit={this.submitBoard}>
                        <Row>
                            <FormGroup as={Col} sm="6" controlId="validationCustom01">
                                <Form.Label>Set a delivery date for your wall (delivery details be changed later if needed)</Form.Label>

                                <Form.Control type="date"
                                              className="DeliveryCreate"
                                              required
                                              min={new Date().toISOString().split("T")[0]}
                                              max={new Date(new Date().getFullYear() + 2, 0, 1).toISOString().split("T")[0]}
                                              onChange={this.handleDeliveryDateChange}
                                              value={this.props.deliveryDate}/>
                                <Form.Control.Feedback type="invalid">
                                    Enter a valid date (either today or in the future).
                                </Form.Control.Feedback>
                            </FormGroup>
                        </Row>
                        <br></br>
                        <Row>
                            <FormGroup as={Col} sm="6" controlId="validationCustom02">
                                <Form.Label>Set a delivery time (use the GMT timezone)</Form.Label>
                                <TimePicker start="00:00"
                                            end="23:59"
                                            step={5}
                                            onChange={this.handleDeliveryTimeChange}
                                            value={this.props.deliveryTime}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Time cannot be empty
                                </Form.Control.Feedback>
                                <br></br>
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Recipient email addresses</Form.Label>
                                <ReactMultiEmail
                                    className="DeliveryCreate"
                                    validateEmail={email => {
                                        return isEmail(email); // return boolean
                                    }}
                                    onChange={this.handleRecipientListChange}
                                    emails={this.props.recipientList}
                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {
                                        return (
                                            <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}/>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={6}>
                                <Form>
                                    <Form.Check
                                        className={"premiumToggle"}
                                        type="switch"
                                        checked={this.props.isPremium}
                                        id="premium-switch"
                                        label={<b>Upgrade to ad-free Wall for £2.49? (Recommended)</b>}
                                        onChange={(e) => this.setIsPremium(e)}
                                    />
                                </Form>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <br></br>
                                <Button
                                    type="submit"
                                    onClick={() => this.setState({submitPressed: "save"})}
                                    style={{
                                        float: "right",
                                        backgroundColor: " #8639F1",
                                        borderColor: " #8639F1",
                                        width: "100%",
                                        boxShadow: "0px 2px 5px -1px #333"
                                    }}>
                                    Save Wall As Draft <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <br></br>
                                <div style={{textAlign: "center"}}> Or</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <br></br>
                                <Button
                                    type="submit"
                                    onClick={() => this.setState({submitPressed: "payment"})}
                                    style={{
                                        float: "right",
                                        backgroundColor: " #8639F1",
                                        borderColor: " #8639F1",
                                        width: "100%",
                                        boxShadow: "0px 2px 5px -1px #333"
                                    }}>
                                    Activate Now <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
    }
}

export default withAuth0(connect(mapStateToProps)(DeliveryComponent));