import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button} from "react-bootstrap";

class PricingComponent extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={6}>
                            <h3><strong>Wall From Us Group Ecard Pricing</strong></h3>
                            <br></br>
                            <h4>Premium ad-free Walls: £2.49 each</h4>
                            <h4>Walls with ads: FREE</h4>
                            <br></br>
                            <h5>What's included in both:</h5>
                            <ul>
                                <li>
                                    <p>Delivery to up to 10 recipients' email addresses at a date and time you specify.</p>
                                </li>
                                <li>
                                    <p>Your wall can hold up to 100 contributions, including photos, messages and GIFs from Giphy.</p>
                                </li>
                                <li>
                                    <p>We will host your wall as long as you want us to.</p>
                                </li>
                                <li>
                                    <p>Choose from a selection of over 100 backgrounds and 40 fonts.</p>
                                </li>
                                <li>
                                    <p>You can edit your wall at any time.</p>
                                </li>
                                <li>
                                    <p>Support will be available, just email support@wallfromus.com.</p>
                                </li>
                                <li>
                                    <p>For more information, read our <a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}help`}>FAQ</a> and <a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}termsandconditions`}>Terms And Conditions</a>.</p>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>)
    }


}

export default PricingComponent;