import React, {Component, useState} from 'react';
import {
    Container,
    Col,
    Row,
    InputGroup,
    FormControl,
    Accordion,
    Card,
    Button,
    Modal,
    Form,
    Dropdown
} from "react-bootstrap";
import amazonAd from "../images/AmazonAd.png"
import {withRouter} from "react-router";
import Picker from 'emoji-picker-react';
import qs from 'qs';
import {
    gql,
    ApolloClient,
    InMemoryCache
} from '@apollo/client';
import TextareaAutosize from "react-textarea-autosize";
import {connect} from "react-redux";
import './ViewWallComponent.css'
import {
    fontColours,
    switchCentreBoolToTextValue,
    switchTransparencyBool,
    fonts,
    noShadowIftransparent, assignConfettiColorsIds
} from "../utils/utils";
import ContributionComponent from "./ContributionComponent";
import Select from "react-select";
import giphyMsg from "../images/PoweredBy_200px-White_HorizText.png"
import ImageUploader from 'react-images-upload';
import {createUploadLink} from 'apollo-upload-client'
import 'react-image-crop/dist/ReactCrop.css';
import {faArrowRight, faCropAlt, faPlus, faHome} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ReactSession} from "react-client-session";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCanvasConfetti from "react-canvas-confetti";
import ConfettiComponent from "./ConfettiComponent";
import GeolocationComponent from "./GeolocationComponent";
import GifPicker from 'gif-picker-react';

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_SERVER_URL}graphql`,
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: `${process.env.REACT_APP_SERVER_URL}graphql`,
    }),
});

const fontSizes = Array.from({length: 40}, (_, i) => i + 21).map(n => ({
    value: n,
    label: n
}))

const AMAZON_ADS_LINKS = {
    "US": "https://www.amazon.com/gp/search?ie=UTF8&tag=wallfromusu0b-20&linkCode=ur2&linkId=0f38084b2b20871b6fc6646b140b875a&camp=1789&creative=9325&index=aps&keywords=Gift ideas",
    "FR": "https://www.amazon.fr/s?k=cadeaux&amp;__mk_fr_FR=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=1ZKF1ZN0A54GD&amp;sprefix=cadeaux%252Caps%252C73&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusu0e-21&linkCode=ur2&linkId=8a14f9e848f08a6d8fe65e2bf8f2c43a&camp=1642&creative=6746",
    "GB": "https://www.amazon.co.uk/s?k=gift+ideas&amp;crid=4SOIPDPR3LBO&amp;sprefix=gift+ideas%252Caps%252C80&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusu03-21&linkCode=ur2&linkId=89d5b0c042a72a51c5b802e182d71600&camp=1634&creative=6738",
    "DE": "https://www.amazon.de/s?k=geschenke&amp;language=de_DE&amp;currency=EUR&_encoding=UTF8&tag=wallfromusu08-21&linkCode=ur2&linkId=7bbb24328435439c4b793bd41b651081&camp=1638&creative=6742",
    "ES": "https://www.amazon.es/s?k=regalos&amp;__mk_es_ES=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=271SDNXAOZD46&amp;sprefix=regalos%252Caps%252C93&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusu00-21&linkCode=ur2&linkId=4febe90948a5778362809a645100c0fd&camp=3638&creative=24630",
    "IT": "https://www.amazon.it/s?k=regalo&amp;__mk_it_IT=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=3H3DVDW569XK4&amp;sprefix=regalo%252Caps%252C90&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusit-21&linkCode=ur2&linkId=452694ebb80450a3444000dfb5279aab&camp=3414&creative=21718",
    "CA": "https://www.amazon.ca/s?k=gift+ideas&amp;crid=2RNQRG1BPMJN1&amp;sprefix=gift+idea%252Caps%252C145&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromuscan-20&linkCode=ur2&linkId=25f144f809ee40e29def526a73b285b4&camp=15121&creative=330641",
    "SG": "https://www.amazon.sg/s?k=gift+ideas&amp;crid=1VX607M2146W8&amp;sprefix=gift+idea%252Caps%252C213&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromussp-22&linkCode=ur2&linkId=073b4389408e062c2e861711983c9eb1&camp=247&creative=1211",
    "AU": "https://www.amazon.com.au/s?crid=3CVBOMFZYIC48&amp;k=gift%2520ideas&amp;ref=glow_cls&amp;refresh=1&amp;sprefix=gift%2520ideas%252Caps%252C204&_encoding=UTF8&tag=wallfromusaus-22&linkCode=ur2&linkId=8234a76be85b28ca4a119f645a4a1a28&camp=247&creative=1211",
    "NL": "https://www.amazon.nl/s?k=gift+ideas&amp;__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=5GGWK2OYNMJP&amp;sprefix=gift+ideas%252Caps%252C75&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusnl-21&linkCode=ur2&linkId=02fd43431079fc7b0ee1ef0c3ad94a28&camp=247&creative=1211",
    "JP": "https://www.amazon.co.jp/s?k=gift+ideas&amp;__mk_ja_JP=%25E3%2582%25AB%25E3%2582%25BF%25E3%2582%25AB%25E3%2583%258A&amp;crid=1UO848I7XWRBT&amp;sprefix=gift+ideas%252Caps%252C189&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromusjap-22&linkCode=ur2&linkId=d3f8eb4c8d2034cd0d2b93a4f127bdfc&camp=247&creative=1211",
    "PL": "https://www.amazon.pl/s?k=gift+ideas&amp;__mk_pl_PL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=214XLZ7J5FTN4&amp;sprefix=gift+ideas%252Caps%252C72&amp;ref=nb_sb_noss_1&_encoding=UTF8&tag=wallfromuspl-21&linkCode=ur2&linkId=bd6517d7cd6498c71b97c66ec24387c8&camp=247&creative=1211"}


const AMAZON_COUNTRY_CODES = ["US", "FR", "GB", "DE", "ES", "IT", "CA", "SG", "AU", "NL", "JP", "PL"]

//const gf = new GiphyFetch('SlBsyziD4SSO7RrUbiOdBDvbYNLR70u1')

class ViewWallComponent extends Component {

    constructor(props) {
        super(props);

        this.uuid = this.props.match.params.uuid;

        this.delivery = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).delivery

        ReactSession.setStoreType("localStorage");
        let localState = ReactSession.get("contributionData")
        let currentTimestamp = new Date().getTime()
        if (localState === undefined || localState["expiryDate"] < currentTimestamp || localState["uuid"] !== this.uuid) {

            localState = {
                uuid: this.uuid,
                expiryDate: currentTimestamp + 60 * 60 * 1000,
                showContributionModal: false,
                editingContribution: false,
                cropping: false,
                crop: null,
                deleteModalActive: false,
                editable: true,
                contribution: {
                    id: null,
                    isCentred: false,
                    isMessageAtTop: false,
                    text: "",
                    font: "Arial, sans-serif",
                    fontSize: 21,
                    fontColour: "#000000",
                    picture: null,
                    croppable: false,
                    imgTemporaryUrl: null,
                    gifSearch: null,
                    embedUrl: null,
                    imageName: null,
                    selectionStart: 0,
                    selectionEnd: 0
                }
            }


            ReactSession.set("contributionData", localState)
        }

        this.state = {
            serverRespondedWithWall: false,
            submitDisabled: false,
            ...localState,
            contributions: [],
            errors: "",
        }

        this.fetchWall(this.uuid)
        this.handleShow = this.handleShow.bind(this);
        this.persistStateToLocalStorage = this.persistStateToLocalStorage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateContributionText = this.updateContributionText.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.updateSelectionArrowKeys = this.updateSelectionArrowKeys.bind(this);
        this.persistContribution = this.persistContribution.bind(this);
        this.onFontSizeChange = this.onFontSizeChange.bind(this);
        this.onFontChange = this.onFontChange.bind(this);
        this.onFontColourChange = this.onFontColourChange.bind(this);
        this.handleCentreTextChange = this.handleCentreTextChange.bind(this);
        this.handleMessageAtTopChange = this.handleMessageAtTopChange.bind(this);
        this.onPictureUpload = this.onPictureUpload.bind(this);
        this.handleGifSearchChange = this.handleGifSearchChange.bind(this);
        this.onGifClick = this.onGifClick.bind(this);
        this.changeCropping = this.changeCropping.bind(this);
        this.applyCrop = this.applyCrop.bind(this);
        this.setCrop = this.setCrop.bind(this);
        this.setImage = this.setImage.bind(this);
        this.onEmojiClick = this.onEmojiClick.bind(this);
        this.deleteContribution = this.deleteContribution.bind(this);
        this.activateDeleteModal = this.activateDeleteModal.bind(this);
        this.activateEditModal = this.activateEditModal.bind(this);
        this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
        this.updateContribution = this.updateContribution.bind(this);
        this.createContribution = this.createContribution.bind(this);
    }

    persistStateToLocalStorage() {

        let {contributions, imageElement, serverRespondedWithWall, ...y} = this.state;
        ReactSession.set("contributionData", y)
    }

    fetchWall(uuid) {
        let shitty_query = gql`
            query GetWall($uuid: String) {
                getActiveWallByUuid(uuid: $uuid)
                {
                    text
                    isCentred
                    isTransparent
                    font
                    fontSize
                    fontColour
                    s3Id
                    s3Path
                    mainImageId
                    mainImagePath
                    personalImagePath
                    createdDate
                    isPaid
                    isConfetti
                    confettiColorsList
                    contributions {
                        id
                        text
                        isCentred
                        font
                        fontColour
                        fontSize
                        s3Url
                        embedUrl
                    }
                }
            }
        `;

        client
            .query({
                query: shitty_query,
                variables: {
                    uuid: this.uuid,
                }
            })
            .then(result => {
                let wall = result.data.getActiveWallByUuid

                if (wall === null) {

                    this.setState({errors: "Active Wall not found"})
                } else {

                    this.setState({
                        text: wall.text,
                        isCentred: wall.isCentred,
                        isTransparent: wall.isTransparent,
                        font: wall.font,
                        fontColour: wall.fontColour,
                        fontSize: wall.fontSize,
                        s3Path: wall.s3Path,
                        createdDate: wall.createdDate,
                        mainImagePath: wall.mainImagePath,
                        personalImagePath: wall.personalImagePath,
                        isPaid: wall.isPaid,
                        isConfetti: wall.isConfetti,
                        confettiColors: wall.confettiColorsList,
                        contributions: wall.contributions,
                        serverRespondedWithWall: true
                    }, () => {
                        this.persistStateToLocalStorage()
                    })
                }
            }).catch(err => {
                this.setState({errors: err.toString()})
            }
        );
    }

    onGifClick(gif) {
        let contribution = this.state.contribution;
        contribution["embedUrl"] = gif.url
        contribution["picture"] = null
        contribution["croppable"] = false
        this.setState({contribution}, () => {
            this.persistStateToLocalStorage()
        });
    }

    handleGifSearchChange(event) {

        let contribution = this.state.contribution;
        contribution["gifSearch"] = event.target.value
        this.setState({contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }


    handleShow() {

        this.setState({showContributionModal: true}, () => {
            this.persistStateToLocalStorage()
        })
    }

    handleClose() {

        let contribution = {
            isCentred: false,
            text: "",
            font: "Arial, sans-serif",
            fontSize: 21,
            fontColour: "#000000",
            id: null,
            imageName: null
        }

        this.setState({
            showContributionModal: false,
            editingContribution: false,
            contribution: contribution
        }, () => {
            this.persistStateToLocalStorage()
        })
    }

    onEmojiClick(e) {
        let contribution = this.state.contribution
        let selectionStart = contribution["selectionStart"] || 0
        let selectionEnd = contribution["selectionEnd"] || 0
        contribution["text"] = contribution["text"].substring(0, selectionStart) +
            e.target.textContent +
            contribution["text"].substring(selectionEnd)
        contribution["selectionStart"] = selectionStart + e.target.textContent.length
        contribution["selectionEnd"] = selectionEnd + e.target.textContent.length
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    updateContributionText(event) {
        let contribution = this.state.contribution
        contribution["text"] = event.target.value
        contribution["selectionStart"] = event.target.selectionStart
        contribution["selectionEnd"] = event.target.selectionEnd
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    updateSelection(event) {
        let contribution = this.state.contribution
        contribution["selectionStart"] = event.target.selectionStart
        contribution["selectionEnd"] = event.target.selectionEnd
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    updateSelectionArrowKeys(event) {
        let contribution = this.state.contribution
        if (event.keyCode === 37) {
            contribution["selectionStart"] = event.target.selectionStart
            contribution["selectionEnd"] = event.target.selectionEnd
        } else if (event.keyCode === 39) {
            contribution["selectionStart"] = event.target.selectionStart
            contribution["selectionEnd"] = event.target.selectionEnd
        }
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    updateContribution() {

        let query = gql`
            mutation UpdateContribution(
                $id: Int
                $text: String
                $isMessageAtTop: Boolean
                $isCentred: Boolean
                $font: String
                $fontColour: String
                $fontSize: Int
                $wallUuid: String
                $image: Upload
                $embedUrl: String
            ) {
                updateContribution(
                    id: $id,
                    text: $text,
                    isMessageAtTop: $isMessageAtTop,
                    isCentred: $isCentred,
                    font: $font,
                    fontColour: $fontColour,
                    fontSize: $fontSize,
                    wallUuid: $wallUuid
                    image: $image,
                    embedUrl: $embedUrl)
                {
                    uuid
                    text
                    isCentred
                    isTransparent
                    font
                    fontSize
                    fontColour
                    s3Id
                    s3Path
                    contributions {
                        id
                        text
                        isMessageAtTop
                        isCentred
                        font
                        fontColour
                        fontSize
                        s3Url
                        embedUrl
                    }
                }
            }
        `;

        this.setState({submitDisabled: true})

        client
            .mutate({
                mutation: query,
                variables: {
                    id: this.state.contribution.id,
                    text: this.state.contribution.text,
                    isMessageAtTop: this.state.contribution.isMessageAtTop,
                    isCentred: this.state.contribution.isCentred,
                    font: this.state.contribution.font,
                    fontColour: this.state.contribution.fontColour,
                    fontSize: this.state.contribution.fontSize,
                    wallUuid: this.uuid,
                    image: this.state.contribution.picture,
                    embedUrl: this.state.contribution.embedUrl
                }
            })
            .then(result => {
                this.setState({
                    submitDisabled: false,
                    text: result.data.updateContribution.text,
                    isCentred: result.data.updateContribution.isCentred,
                    isTransparent: result.data.updateContribution.isTransparent,
                    font: result.data.updateContribution.font,
                    fontColour: result.data.updateContribution.fontColour,
                    fontSize: result.data.updateContribution.fontSize,
                    s3Path: result.data.updateContribution.s3Path,
                    contributions: result.data.updateContribution.contributions
                }, () => {
                    this.persistStateToLocalStorage()
                })
                this.handleClose();
            }).catch(err => {
                toast.error("Contribution update was not successful, please try again later",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true
                    })
                this.setState({submitDisabled: false})
                console.log(`Something went wrong: ${err}`);
            }
        );


    }

    createContribution() {
        let query = gql`
            mutation CreateContribution(
                $text: String
                $isMessageAtTop: Boolean
                $isCentred: Boolean
                $font: String
                $fontColour: String
                $fontSize: Int
                $wallUuid: String
                $image: Upload
                $embedUrl: String
            ) {
                createContribution(
                    text: $text,
                    isMessageAtTop: $isMessageAtTop,
                    isCentred: $isCentred,
                    font: $font,
                    fontColour: $fontColour,
                    fontSize: $fontSize,
                    wallUuid: $wallUuid
                    image: $image,
                    embedUrl: $embedUrl)
                {
                    uuid
                    text
                    isCentred
                    isTransparent
                    font
                    fontSize
                    fontColour
                    s3Id
                    s3Path
                    contributions {
                        id
                        text
                        isMessageAtTop
                        isCentred
                        font
                        fontColour
                        fontSize
                        s3Url
                        embedUrl
                    }
                }
            }
        `;

        this.setState({submitDisabled: true})

        client
            .mutate({
                mutation: query,
                variables: {
                    text: this.state.contribution.text,
                    isMessageAtTop: this.state.contribution.isMessageAtTop,
                    isCentred: this.state.contribution.isCentred,
                    font: this.state.contribution.font,
                    fontColour: this.state.contribution.fontColour,
                    fontSize: this.state.contribution.fontSize,
                    wallUuid: this.uuid,
                    image: this.state.contribution.picture,
                    embedUrl: this.state.contribution.embedUrl
                }
            })
            .then(result => {
                this.setState({
                    submitDisabled: false,
                    text: result.data.createContribution.text,
                    isCentred: result.data.createContribution.isCentred,
                    isTransparent: result.data.createContribution.isTransparent,
                    font: result.data.createContribution.font,
                    fontColour: result.data.createContribution.fontColour,
                    fontSize: result.data.createContribution.fontSize,
                    s3Path: result.data.createContribution.s3Path,
                    contributions: result.data.createContribution.contributions
                }, () => {
                    this.persistStateToLocalStorage()
                })
                this.handleClose();
            }).catch(err => {
                toast.error("Contribution creation was not successful, please try again later",
                    {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true
                    })
                this.setState({submitDisabled: false})
                console.log(`Something went wrong ${err}`);
            }
        );
    }

    persistContribution() {

        if (this.state.contribution.id != null) {
            this.updateContribution()
        } else {
            this.createContribution()
        }
    }

    onFontChange(e) {

        let contribution = this.state.contribution
        contribution["font"] = e.value
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    onFontColourChange(e) {
        let contribution = this.state.contribution
        contribution["fontColour"] = e.value
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    onFontSizeChange(e) {
        let contribution = this.state.contribution
        contribution["fontSize"] = e.value
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    handleCentreTextChange(e) {
        let contribution = this.state.contribution
        contribution["isCentred"] = e.target.checked
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    handleMessageAtTopChange(e) {
        let contribution = this.state.contribution
        contribution["isMessageAtTop"] = e.target.checked
        this.setState({contribution: contribution}, () => {
            this.persistStateToLocalStorage()
        })
    }

    setCrop(e) {
        this.setState({crop: e}, () => {
            this.persistStateToLocalStorage()
        })
    }

    setImage(img) {
        this.setState({imageElement: img}, () => {
            this.persistStateToLocalStorage()
        })
    }

    onPictureUpload(picture) {
        let contribution = this.state.contribution
        contribution["picture"] = picture[0]
        if (picture[0] != undefined) {
            let imgTemporaryUrl = URL.createObjectURL(picture[0])
            contribution["embedUrl"] = null
            contribution["imageName"] = picture[0].name
            contribution["croppable"] = true
            contribution["imgTemporaryUrl"] = imgTemporaryUrl
            this.setState({contribution: contribution}, () => {
                this.persistStateToLocalStorage()
            })
        }
    }

    changeCropping() {
        if (this.state.cropping === false) {
            this.setState({cropping: true}, () => {
                this.persistStateToLocalStorage()
            })
        } else {
            this.setState({cropping: false}, () => {
                this.persistStateToLocalStorage()
            })
        }
    }

    applyCrop(e) {

        if (this.state.crop.width !== 0 && this.state.crop.height !== 0) {

            let image = this.state.imageElement
            let crop = this.state.crop

            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const base64Image = canvas.toDataURL("image/jpeg", 1);
            this.setState({crop: {}, cropping: false}, () => {
                this.persistStateToLocalStorage()
            })
            fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "cropped_" + this.state.contribution.imageName, blob)
                    let contribution = this.state.contribution
                    contribution["imgTemporaryUrl"] = base64Image;
                    contribution["picture"] = file
                    contribution["croppable"] = false
                    this.setState({contribution: contribution}, () => {
                        this.persistStateToLocalStorage()
                    })
                })

        }

    }


    deleteContribution() {

        let query = gql`
            mutation DeleteContribution(
                $id: Int
                $wallUuid: String
            ) {
                deleteContribution(
                    id: $id,
                    wallUuid: $wallUuid)
                {
                    uuid
                    text
                    isCentred
                    isTransparent
                    font
                    fontSize
                    fontColour
                    s3Id
                    s3Path
                    contributions {
                        id
                        text
                        isMessageAtTop
                        isCentred
                        font
                        fontColour
                        fontSize
                        s3Url
                        embedUrl
                    }
                }
            }
        `;

        client
            .mutate({
                mutation: query,
                variables: {
                    id: this.state.contributionToDelete,
                    wallUuid: this.uuid
                }
            })
            .then(result => {
                this.setState({
                    text: result.data.deleteContribution.text,
                    isCentred: result.data.deleteContribution.isCentred,
                    isTransparent: result.data.deleteContribution.isTransparent,
                    font: result.data.deleteContribution.font,
                    fontColour: result.data.deleteContribution.fontColour,
                    fontSize: result.data.deleteContribution.fontSize,
                    s3Path: result.data.deleteContribution.s3Path,
                    contributions: result.data.deleteContribution.contributions,
                    deleteModalActive: false,
                }, () => {
                    this.persistStateToLocalStorage()
                })
            }).catch(err => {
                this.setState({
                    deleteModalActive: false,
                })
                toast.error("Contribution deletion was not successful, please try again later",
                    {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true
                    })
                console.log(`Something went wrong ${err}`);
            }
        );

    }

    activateDeleteModal(id) {
        this.setState({
            "deleteModalActive": true,
            "contributionToDelete": id
        }, () => {
            this.persistStateToLocalStorage()
        })
    }

    activateEditModal(id) {

        let contributions = this.state.contributions.filter((c) => c.id === id)

        this.setState({
            showContributionModal: true,
            editingContribution: true,
            contribution: {
                ...contributions[0]
            }
        }, () => {
            this.persistStateToLocalStorage()
            this.textarea.click();
        })
    }

    handleDeleteModalClose() {
        this.setState({"deleteModalActive": false}, () => {
            this.persistStateToLocalStorage()
        })
    }

    render() {

        let imagesUploaded;

        if (this.state.contribution.picture != null) {
            imagesUploaded = <p>Image Uploaded: {this.state.contribution.picture.name}</p>;
        } else {
            imagesUploaded = <p></p>;
        }

        let applyButton;

        if (this.state.cropping) {
            applyButton = <Button variant="secondary" title={"Apply Crop"} onClick={this.applyCrop}>Apply Crop</Button>
        }

        let cropButton;

        if (this.state.contribution.croppable) {
            cropButton = <Button variant="secondary" title={"Crop Image"} onClick={this.changeCropping}>
                <FontAwesomeIcon icon={faCropAlt}></FontAwesomeIcon>
            </Button>
        }

        let editable = this.state.editable
        let modalTitle;
        let messageTitle;
        if (this.state.editingContribution === true) {
            modalTitle = "Edit Contribution"
            messageTitle = "Edit Message"
        } else {
            modalTitle = "Add a Contribution"
            messageTitle = "Add a Message"
        }

        let activateDeleteModal = this.activateDeleteModal
        let activateEditModal = this.activateEditModal

        let editpic = this.state.contribution.imgTemporaryUrl

        if (this.state.contribution.imgTemporaryUrl === undefined)
            editpic = this.state.contribution.s3Url

        if (this.state.errors !== "") {
            return (<div>
                <br></br>
                <Row>
                    <h6>Server Error</h6>
                    <p>{this.state.errors}</p>
                </Row>
            </div>)
        }

        let submitDisabled = this.state.submitDisabled

        let createdDate = Date.parse(this.state.createdDate);
        let v2Date = Date.parse("2025-02-04")

        let isV2 = createdDate > v2Date

        let showAd = AMAZON_COUNTRY_CODES.includes(this.props.viewWallCountry) && !this.state.isPaid && isV2

        let height = (showAd) ? "72.5vh": "95vh"

        let mainImageStyle = {
            minHeight: height,
            maxHeight: height,
            marginTop: "2.5vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            borderRadius: 10,
            boxShadow: "0px 2px 5px -1px #333"
        }

        // marginTop: "70px",
        //     minHeight: "400px",
        //     maxHeight: "550px",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     display: "block",
        //     borderRadius: 10,
        //     boxShadow: "0px 2px 5px -1px #333"

        let personalImageHeight = showAd ? "47vh": "70vh"


        let personalImageStyle = {
            minHeight: personalImageHeight,
            maxHeight: personalImageHeight,
            marginTop: "2.5vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            borderRadius: 10,
            boxShadow: "0px 2px 5px -1px #333"
        }

        return (
            <div>
                <ToastContainer/>
                <>
                    {!this.state.isPaid && <div>
                        <GeolocationComponent dispatch={this.props.dispatch}></GeolocationComponent>
                    </div>}
                    <Modal show={this.state.deleteModalActive} onHide={this.handleDeleteModalClose} size={"xs"}>
                        <Modal.Header>
                            <Modal.Title>Are you sure you want to delete this contribution?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container fluid>
                                <Row>
                                    <Col sm={4}>
                                    </Col>
                                    <Col sm={4}>
                                        <Button variant="primary" style={{
                                            width: "100%", backgroundColor: "#8639F1",
                                            borderColor: "#8639F1", boxShadow: "0px 2px 5px -1px #333"
                                        }} onClick={() => this.deleteContribution()}>Delete</Button>
                                    </Col>
                                    <Col sm={4}>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showContributionModal} onHide={this.handleClose} size={"xl"}>

                        <Modal.Header>
                            <Container fluid>
                                <br></br>
                                <Row>
                                    <Col sm={7}>
                                        <Modal.Title>{modalTitle}</Modal.Title>
                                    </Col>
                                    <Col sm={5}>
                                        <div>
                                            <Button style={{width: "150px", boxShadow: "0px 2px 5px -1px #333"}}
                                                    className="PersistBtn" variant="primary"
                                                    disabled={submitDisabled}
                                                    onClick={this.persistContribution}>
                                                Submit <FontAwesomeIcon icon={faArrowRight}/>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>

                            <Container fluid>
                                <Row>
                                    <Col sm={7}>
                                        <Accordion defaultActiveKey={"0"}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>{messageTitle}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div style={{
                                                        backgroundColor: switchTransparencyBool(this.props.isTransparent),
                                                        marginLeft: "20px",
                                                        marginTop: "20px",
                                                        textAlign: "center",
                                                        marginRight: "20px",
                                                        maxWidth: "100%",
                                                        borderRadius: "10px",
                                                    }}>
                                                        <TextareaAutosize

                                                            ref={(tag) => (this.textarea = tag)}

                                                            style={{
                                                                whiteSpace: "pre-line",
                                                                opacity: 1,
                                                                backgroundColor: "rgba(0, 0, 0, 0.0)",
                                                                borderRadius: "5px",
                                                                overflow: "hidden",
                                                                resize: "none",
                                                                textAlign: switchCentreBoolToTextValue(this.state.contribution.isCentred),
                                                                width: "78%",
                                                                fontSize: this.state.contribution.fontSize,
                                                                color: this.state.contribution.fontColour,
                                                                fontFamily: this.state.contribution.font
                                                            }} onChange={this.updateContributionText}
                                                            placeholder={'Enter your message here'}
                                                            maxLength={2000}
                                                            value={this.state.contribution.text}
                                                            onClick={this.updateSelection}
                                                            onKeyUp={this.updateSelectionArrowKeys}
                                                            onselect={this.updateSelection}>

                                                        </TextareaAutosize>
                                                    </div>
                                                    <Container fluid>
                                                        <Row>
                                                            <Col sm={4}>
                                                                <div title="Font">
                                                                    <Select
                                                                        style={{
                                                                            display: "block",
                                                                            margin: "0 auto",
                                                                            width: "100%"
                                                                        }}
                                                                        options={fonts}
                                                                        value={fonts.filter(x => x.value === this.state.contribution.font)[0]}
                                                                        onChange={this.onFontChange}/>
                                                                </div>
                                                                <br/>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div title="Font Colour">
                                                                    <Select options={fontColours}
                                                                            value={fontColours.filter(x => x.value === this.state.contribution.fontColour)[0]}
                                                                            onChange={this.onFontColourChange}
                                                                    />
                                                                </div>
                                                                <br/>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div title="Font Size">
                                                                    <Select
                                                                        style={{
                                                                            display: "block",
                                                                            margin: "0 auto",
                                                                            width: "100%"
                                                                        }}
                                                                        options={fontSizes}
                                                                        value={fontSizes.filter(x => x.value === this.state.contribution.fontSize)[0]}
                                                                        onChange={this.onFontSizeChange}/>
                                                                </div>
                                                                <br></br>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Dropdown autoClose="outside" className="EmojiDropdown">
                                                                    <Dropdown.Toggle>
                                                                        😁
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item><Picker
                                                                            onEmojiClick={this.onEmojiClick}
                                                                            native={true}/></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Form>
                                                                    <div className="checkboxes">
                                                                        <label className="text-radio-label">
                                                                            <input
                                                                                className="text-radio-input"
                                                                                name="centreText"
                                                                                type="checkbox"
                                                                                checked={this.state.contribution.isCentred}
                                                                                onChange={this.handleCentreTextChange}/>
                                                                            <span className="text-radio-span"> Center Text</span>
                                                                        </label>
                                                                        <label className="text-radio-label">
                                                                            <input
                                                                                className="text-radio-input"
                                                                                name="centreText"
                                                                                type="checkbox"
                                                                                checked={this.state.contribution.isMessageAtTop}
                                                                                onChange={this.handleMessageAtTopChange}/>
                                                                            <span className="text-radio-span"> Message at top</span>
                                                                        </label>
                                                                    </div>
                                                                </Form>
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    Add an Image
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ImageUploader
                                                        withLabel={true}
                                                        label={"Upload JPG and PNG formats only, up to 5Mb in size."}
                                                        singleImage={true}
                                                        withIcon={true}
                                                        buttonText='Choose images'
                                                        onChange={this.onPictureUpload}
                                                        imgExtension={['.jpg', '.png', '.jpeg']}
                                                        maxFileSize={5242880}
                                                    />
                                                    {imagesUploaded}
                                                    <Row>
                                                        <Col sm={2}>
                                                            {cropButton}
                                                        </Col>
                                                        <Col sm={8}>
                                                        </Col>
                                                        <Col sm={2}>
                                                            {applyButton}
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Add a Meme</Accordion.Header>
                                                <Accordion.Body>

                                                    <p style={{textAlign: "center"}}>Build amazing Memes using your own
                                                        or other popular Images and Gifs at <a
                                                            href={"https://imgflip.com/memegenerator"}
                                                            style={{color: "#000"}}>ImgFlip</a> and then upload them to
                                                        your wall</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <div>
                                            <GifPicker tenorApiKey={"AIzaSyBho39TDWj10GREiji4Ql2oBhM_JTei3Oc"}
                                                       contentFilter={"high"}
                                                       onGifClick={this.onGifClick}
                                                       height={500} width={"100%"}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <div><ContributionComponent text={this.state.contribution.text}
                                                                    isCentred={this.state.contribution.isCentred}
                                                                    font={this.state.contribution.font}
                                                                    fontColour={this.state.contribution.fontColour}
                                                                    fontSize={this.state.contribution.fontSize}
                                                                    embeddedGifUrl={this.state.contribution.embedUrl}
                                                                    s3Url={editpic}
                                                                    cropActivated={this.state.cropping}
                                                                    setCrop={this.setCrop}
                                                                    isMessageAtTop={this.state.contribution.isMessageAtTop}
                                                                    setImage={this.setImage}
                                                                    crop={this.state.crop}
                                        /><br></br></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                    <div style={{
                        paddingTop: "1px",
                        background: `url(${this.state.s3Path})`,
                        minHeight: "100vh",
                        minWidth: "100%"
                    }}>

                        <Container fluid>

                            {
                                this.delivery === "true" && this.state.isConfetti === true && this.state.serverRespondedWithWall === true &&
                                <Row>
                                    <ConfettiComponent colors={this.state.confettiColors}></ConfettiComponent>
                                </Row>
                            }
                            <Row>
                                {showAd &&
                                <div>
                                    <Row>
                                        <Col sm={12} style={{alignItems: 'center'}}>
                                            <a href={AMAZON_ADS_LINKS[this.props.viewWallCountry]}
                                               target={"_blank"}><img src={amazonAd} style={{
                                                   marginTop: "2.5vh",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                display: "block",
                                                maxHeight: "20vh",
                                                minHeight: "20vh"
                                            }}></img></a>
                                        </Col>
                                    </Row>
                                </div>
                                }
                            </Row>
                            {this.state.mainImagePath != null && this.state.personalImagePath == null &&
                            <Row>
                                <div>
                                    <img style={mainImageStyle} src={this.state.mainImagePath}>
                                    </img>
                                </div>
                            </Row>}
                            {this.state.personalImagePath != null && this.state.mainImagePath == null &&
                            <Row>
                                <Col sm={12}>
                                <div>
                                    <img style={personalImageStyle} src={this.state.personalImagePath}>
                                    </img>
                                </div>
                                </Col>
                            </Row>}
                            <Row>
                                <Col sm={12}>
                                    <div style={{
                                        backgroundColor: switchTransparencyBool(this.state.isTransparent),
                                        maxWidth: "100%",
                                        minHeight: "100px",
                                        marginTop: "2.5vh",
                                        borderRadius: "10px",
                                        padding: "2%",
                                        boxShadow: noShadowIftransparent(this.state.isTransparent),
                                    }}>

                                        <TextareaAutosize
                                            style={{
                                                whiteSpace: "pre-line",
                                                opacity: 1,
                                                resize: "none",
                                                overflow: "hidden",
                                                lineHeight: this.state.fontSize * 2 + "px",
                                                backgroundColor: "rgba(0, 0, 0, 0.0)",
                                                borderColor: "rgba(0, 0, 0, 0.0)",
                                                textAlign: switchCentreBoolToTextValue(this.state.isCentred),
                                                fontSize: this.state.fontSize,
                                                color: this.state.fontColour,
                                                caretColor: "rgba(0,0,0,0)",
                                                width: "100%",
                                                fontFamily: this.state.font,
                                            }}
                                            value={this.state.text}>

                                        </TextareaAutosize>
                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                <div style={{width: "100%"}}>
                                    <button
                                        title={"Home"}
                                        style={{
                                            backgroundColor: " #FFFFFF",
                                            borderColor: "#FFF",
                                            borderWidth: "0px",
                                            width: 70,
                                            height: 70,
                                            marginRight: "0px",
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            float: "right",
                                            borderRadius: 50,
                                            boxShadow: "0px 2px 5px -1px #333"
                                        }}>
                                        <a href={`${process.env.REACT_APP_UI_URL}home`}>
                                            <FontAwesomeIcon style={{color: '#585858'}} icon={faHome}/></a>

                                    </button>
                                    {this.state.contributions.length < 100 && <Button onClick={this.handleShow}
                                                                                      title={"Contribute"}
                                                                                      style={{
                                                                                          backgroundColor: " #FFFFFF",
                                                                                          borderColor: "#FFF",
                                                                                          width: 70,
                                                                                          height: 70,
                                                                                          marginRight: "5px",
                                                                                          marginTop: "20px",
                                                                                          marginBottom: "20px",
                                                                                          float: "right",
                                                                                          borderRadius: 50,
                                                                                          boxShadow: "0px 2px 5px -1px #333"
                                                                                      }}>
                                        <FontAwesomeIcon style={{color: '#585858'}} icon={faPlus}/>
                                    </Button>}
                                    <br></br>
                                </div>
                                <Col sm={4}>
                                    {
                                        this.state.contributions.map(function (contribution, i) {
                                            if (i % 3 === 0) {
                                                return (<div><ContributionComponent text={contribution.text}
                                                                                    isCentred={contribution.isCentred}
                                                                                    font={contribution.font}
                                                                                    fontColour={contribution.fontColour}
                                                                                    fontSize={contribution.fontSize}
                                                                                    s3Url={contribution.s3Url}
                                                                                    embeddedGifUrl={contribution.embedUrl}
                                                                                    isMessageAtTop={contribution.isMessageAtTop}
                                                                                    key={i}
                                                                                    cropActivated={false}
                                                                                    editable={editable}
                                                                                    id={contribution.id}
                                                                                    activateDeleteModal={activateDeleteModal}
                                                                                    activateEditModal={activateEditModal}
                                                /><br></br></div>);
                                            }
                                        })}
                                </Col>
                                <Col sm={4}>
                                    {this.state.contributions.map(function (contribution, i) {
                                        if (i % 3 === 1) {
                                            return (<div><ContributionComponent text={contribution.text}
                                                                                isCentred={contribution.isCentred}
                                                                                font={contribution.font}
                                                                                fontColour={contribution.fontColour}
                                                                                fontSize={contribution.fontSize}
                                                                                s3Url={contribution.s3Url}
                                                                                embeddedGifUrl={contribution.embedUrl}
                                                                                isMessageAtTop={contribution.isMessageAtTop}
                                                                                key={i}
                                                                                cropActivated={false}
                                                                                editable={editable}
                                                                                id={contribution.id}
                                                                                activateDeleteModal={activateDeleteModal}
                                                                                activateEditModal={activateEditModal}
                                            /><br></br></div>);
                                        }
                                    })}
                                </Col>
                                <Col sm={4}>
                                    {this.state.contributions.map(function (contribution, i) {
                                        if (i % 3 === 2) {
                                            return (<div><ContributionComponent text={contribution.text}
                                                                                isCentred={contribution.isCentred}
                                                                                font={contribution.font}
                                                                                fontColour={contribution.fontColour}
                                                                                fontSize={contribution.fontSize}
                                                                                s3Url={contribution.s3Url}
                                                                                embeddedGifUrl={contribution.embedUrl}
                                                                                isMessageAtTop={contribution.isMessageAtTop}
                                                                                key={i}
                                                                                cropActivated={false}
                                                                                editable={editable}
                                                                                id={contribution.id}
                                                                                activateDeleteModal={activateDeleteModal}
                                                                                activateEditModal={activateEditModal}
                                            /><br></br></div>);
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </>
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        contribution: {
            text: state.getIn(["centralState", "contribution", "text"]),
            font: state.getIn(["centralState", "contribution", "font"]),
            fontSize: state.getIn(["centralState", "contribution", "fontSize"]),
            fontColor: state.getIn(["centralState", "contribution", "fontColor"])
        },
        viewWallCountry: state.getIn(["centralState", "viewWallCountry"])
    }
}

export default withRouter(connect(mapStateToProps)(ViewWallComponent));